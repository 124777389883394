import React from 'react';
import ReactDOM from 'react-dom';

const LoadingSpinner = ({isStatic, className}) => {
    const classes = ['spinner'];
    if (isStatic) classes.push('static');
    if (className) classes.push(className);
    return ReactDOM.createPortal(
        <div className={classes.join(' ')}/>,
        document.getElementById('spinner')
    );
};

export default LoadingSpinner;
require('dotenv').config();

const { createAlchemyWeb3 } = require("@alch/alchemy-web3");

const alchemyKey       = process.env.REACT_APP_ALCHEMY_URL;
const contractAddress  = process.env.REACT_APP_CONTRACT_ADDRESS;

const TOTAL_MAXONS     = 3888;

const FORCE_SALE_INACTIVE = false;

const web3             = createAlchemyWeb3(alchemyKey);
const contract         = require("./contracts/MaxonBollocks.json");
const nftContract      = new web3.eth.Contract(contract.abi, contractAddress);

export const checkIsSaleActive = () => {
    return new Promise(resolve => {
        if (FORCE_SALE_INACTIVE) {
            resolve(false);
        } else {
            nftContract.methods.mintEnabled().call(
                (err, res) =>
                    (err ? resolve(false) : resolve(res))
            )
        }
    });
};

const fetchReserveLeft = () => {
    return new Promise(resolve => {
        nftContract.methods.getReserveLeft().call(
            (err, res) => (err ? resolve(0) : resolve(res))
        )
    });
};

const fetchTotalSupply = () => {
    return new Promise(resolve => {
        nftContract.methods.totalSupply().call(
            (err, res) => (err ? resolve(0) : resolve(res))
        )
    });
};

export const fetchMaxonsLeft = async () => {
    const [reserveLeft, totalSupply] = await Promise.all([
        fetchReserveLeft(), fetchTotalSupply()
    ]);

    return TOTAL_MAXONS - reserveLeft - totalSupply;
};

export function catchEm(promise) {
    return promise.then(data => [null, data])
        .catch(err => [err]);
}

export const mintMaxons = async(amount, value) => {
    //console.log('will call mint with ', amount, value);

    const validAmounts = [1,5,10,15,20];
    if (validAmounts.indexOf(amount)<0) {
        return {
            success: false,
            status: "❗ You can only mint 1, 5, 10, 15 or 20 Maxons",
        }
    }

    const method = nftContract.methods.mint(amount);
    let gasEstimate = 0;

    const [error, gas] = await catchEm(method.estimateGas({
        from: window.ethereum.selectedAddress,
        value: value
    }));

    if (error) {
        return {
            success: false,
            status: "😥 " + error
        }
    }   else {
        gasEstimate = gas;
    }

    //console.log('gas estimate is ', gasEstimate);

    try {
        //set up your Ethereum transaction
        const transactionParameters = {
            to: contractAddress,
            from: window.ethereum.selectedAddress,
            value: value.toString(16),
            'gas': parseInt(gasEstimate*1.15).toString(16),
            'data': method.encodeABI()
        };

        if(navigator.userAgent.match('CriOS')) {
            delete transactionParameters.gas;
        }

        //sign the transaction via Metamask
        const txHash = await window.ethereum
            .request({
                method: 'eth_sendTransaction',
                params: [transactionParameters],
            });
        return {
            success: true,
            status: "✅ Check out your transaction on Etherscan:\n https://etherscan.io/tx/" + txHash
        }
    }   catch (error) {
        const message = (typeof error === 'string' || error instanceof String) ? error :
            ((typeof error === "object" || error instanceof Object) && error.hasOwnProperty("message") ? error.message : error)
        return {
            success: false,
            status: "😥 " + message
        }
    }   finally {
    }
};
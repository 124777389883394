import { Component } from "react";
import { useMetaMask } from "metamask-react";
import LoadingSpinner from "./LoadingSpinner";
import { mintMaxons, checkIsSaleActive, fetchMaxonsLeft } from "./contract.js";

const PRICE_PER_1  = 0.080; // =  1 x 0.080
const PRICE_PER_5  = 0.375; // =  5 x 0.075
const PRICE_PER_10 = 0.650; // = 10 x 0.065
const PRICE_PER_15 = 0.900; // = 15 x 0.060
const PRICE_PER_20 = 1.100; // = 20 x 0.055

class Minter extends Component {

    constructor() {
        super();
        this.state = {
            price: -1,
            isSaleActive: false,
            feedback: '',
            maxonsLeft: 3888,
        };
    }

    componentDidMount () {
        checkIsSaleActive()
            .then(isActive => this.setState({isSaleActive: isActive}))
            .catch(e => (0));

        fetchMaxonsLeft()
            .then(count => this.setState({maxonsLeft: count}))
            .catch(e => (0));
    }

    mintPunks = (amount, price) => {
        this.setState({feedback: ''});

        try {
            mintMaxons(amount, price)
                .then(response => this.setState({feedback: response.status}))
                .catch(e => this.setState({feedback: e}));
        }   catch (e) {
        }
    };

    render() {
        const {feedback, maxonsLeft} = this.state;

        return (
            <div className='page-root'>
                <div className="minter">
                    <TopMenu />
                    <p className="title">
                        Mint
                        <span className="subtitle">
                            {maxonsLeft} / 3888 left
                        </span>
                    </p>
                    <div className="instructions">
                        Step 1 - Connect your Metamask<br/>
                        Step 2 - Select the number of Maxons to mint
                    </div>

                    <div className="mint-block">
                        <MintSwitch mint={this.mintPunks}
                                    price={this.state.price}
                                    isActive={this.state.isSaleActive}
                                    feedback={this.state.feedback}
                                    maxons={maxonsLeft}
                        />
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

const TopMenu = () => {
    const { status, connect, account } = useMetaMask();

    return <header>
        <ul className="menu">
            <li className="logo-item">
                <img alt="Maxon Bollocks"
                     className="logo-image"
                     src="https://maxonbollocks.com/wp-content/uploads/2021/07/image_2021_07_20T10_24_41_018Z.png" />
            </li>
            <li className="menu-item">
                <a href="https://maxonbollocks.com/abstract/">
                    About
                </a>
            </li>
            <li className="menu-item">
                <a href="https://maxonbollocks.com/blog/">
                    Story
                </a>
            </li>
            <li className="menu-item">
                <a href="https://maxonbollocks.com/tokenomics/">
                    Tokenomics
                </a>
            </li>
            <li className="menu-item">
                <a href="https://maxonbollocks.com/the-art/">
                    The Art
                </a>
            </li>
            <li className="menu-item">
                <a href="https://maxonbollocks.com/faq/">
                    FAQ
                </a>
            </li>
        </ul>
        {
            status === 'notConnected' &&
            <button onClick={connect}
                    className="connect-button">Connect
            </button>
        }
        {/*
            status === 'connected' &&
            <span className="account">Welcome, {account}</span>
        */}
    </header>
};

const Footer = () => {
    return <footer>
        <ul className="menu">
            <li className="menu-item">
                <a href="https://maxonbollocks.com/abstract/">
                    About
                </a>
            </li>
            <li className="menu-item">
                <a href="https://maxonbollocks.com/blog/">
                    Story
                </a>
            </li>
            <li className="menu-item">
                <a target="_blank" rel="noreferrer" href="https://etherscan.io/address/0xd9aaf0b1733159a4628ecf718a9a3920691891ad#code">
                    Contract Source
                </a>
            </li>
            <li className="menu-item">
                <a href="mailto:support@moba.gallery">
                    Contact
                </a>
            </li>
            <li className="menu-item">
                <a href="https://maxonbollocks.com/terms/">
                    Terms
                </a>
            </li>
        </ul>
        <div className="copyright">
            <span>© 2021 MoBA Ventures Pty Ltd. All rights reserved</span>
            <a target="_blank" rel="noreferrer" href="https://www.twitter.com/MaxonBollocks">
                <img alt="twitter" src="twitter.svg" />
            </a>
        </div>
    </footer>;
};

const MintSwitch = ({mint, price, isActive, feedback, maxons}) => {
    const { status, connect } = useMetaMask();

    const mintPage = () => {
        if (!maxons) {
            return <p className="description">
                <b>All Maxons have been minted</b><br/>
                For resales, check out our collection on&nbsp;
                <a href="https://opensea.io/collection/maxonbollocks" target="_blank" rel="noreferrer">
                    opensea
                </a>.
            </p>
        }   else
        if (isActive) {
            const amounts = [1, 5, 10, 15, 20];
            const prices = [PRICE_PER_1, PRICE_PER_5, PRICE_PER_10, PRICE_PER_15, PRICE_PER_20];

            return <ul className='mint-list'>
                {amounts.map((amount, index) =>
                    amount <= maxons ?
                    (<li className='mint-item' key={index}>
                        <div className='mint-details'>
                            <span className="mint-title">
                                {amount} Maxon{amount > 1 ? 's' : ''}
                            </span>
                            <span className='mint-info'>
                                {(prices[index] / amount).toFixed(3)} ETH + GAS
                            </span>
                            {
                                amount > 1 &&
                                <span className='mint-info'>
                                    Total {prices[index].toFixed(3)} ETH + GAS
                                </span>
                            }
                        </div>
                        <button onClick={() => (mint(amount, prices[index] * 1e18 ))} className="mintButton">
                            Mint
                        </button>
                    </li>
                    ) : ""
                )}
            </ul>
        }   else {
            return <p className="description">
                <b>Sorry, the sale is not yet active. Please try again later.</b>
                <br/><br/>
            </p>
        }
    };

    switch (status) {
        case 'unavailable':
            return <p className="description">
                <b>You haven't installed MetaMask.</b><br/>
                Please go to the official MetaMask website to download it.
            </p>;
        case 'notConnected':
            return <div>
                <button onClick={connect}
                        className="submit-button">Connect
                </button>
            </div>;
        case 'connecting':
        case 'initializing':
            return <LoadingSpinner className="overlay"/>;

        case 'connected':
            return <div>
                <div className="description">

                </div>
                {   feedback &&
                <div className="feedback">
                    {feedback}
                </div>
                }
                {
                    mintPage()
                }
            </div>;

        default:
            return <div />;
    }
};

export default Minter;
